/*A brands listing for SEO optimisation - draft
Future planned versions to be content managed via Magnolia
Brands to be dynamically delivered via Search API
*/

import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import _groupBy from 'lodash/groupBy';
import Page from '../../components/Page';
import styles from './brands.module.scss';

const Brands = ({ data: { allBrandLink } }) => {
  const linkGroups = _groupBy(allBrandLink.nodes, ({ text: [firstLetter] }) =>
    /[a-zA-Z]/.test(firstLetter) ? firstLetter.toUpperCase() : '0-9',
  );

  const [headerHeight, setHeaderHeight] = useState(0);
  const refContainer = useRef();
  useEffect(() => {
    if (refContainer.current) setHeaderHeight(refContainer.current.offsetTop);
  }, []);

  return (
    <Page>
      <div className={styles.brandsContainer} ref={refContainer}>
        <h1 className={styles.brandsContainer_heading}>Shop by Brand A-Z</h1>
        <div className={styles.brandsContainer_alphabetsContainer}>
          <ul className={styles.brandsContainer_alphabetList}>
            {Object.keys(linkGroups).map(list => {
              return (
                <li key={list}>
                  <AnchorLink
                    offset={headerHeight}
                    href={`#${list}`}
                    className={styles.brandsContainer_alphabetListTag}
                  >
                    {list}
                  </AnchorLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.brandsContainer_brandsNameContainer}>
          {Object.keys(linkGroups).map(list => {
            return (
              <div id={list} key={list}>
                <h1 className={styles.brandsContainer_brandName}>{list}</h1>
                <ul className={styles.brandsContainer_brandList}>
                  {linkGroups[list].map(item => {
                    return (
                      <li
                        key={item.id}
                        className={styles.brandsContainer_brandListItem}
                      >
                        <a
                          href={item.href}
                          className={styles.brandsContainer_brandListTag}
                        >
                          {item.text}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default Brands;

export const pageQuery = graphql`
  query {
    allBrandLink {
      nodes {
        id
        href
        text
      }
    }
  }
`;
